import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { agencyNavBarList, sellerNavBarList } from "../../config/navBarList";
import { useSelector } from "react-redux";
import { RootState } from "../../types/state";
import ArrowIcon from "../../assets/images/seller-leads/arrow-icon.png";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import styled from "styled-components";
import { getAgencyProfile } from "../../network-requests";
import Loading from "../../components/Loading";

export const MaskedIcon = styled.div<{
	selected: boolean;
	icon: any;
	size?: number | string;
}>`
	background-color: ${({ selected }: { selected: boolean; icon: any }) =>
		selected ? "#457bee" : "#6C768F"};
	-webkit-mask-image: url(${(props: any) => props?.icon});
	mask-image: url(${(props: any) => props?.icon});
	width: ${({ size }) =>
		size ? (String(size).endsWith("px") ? size : `${size}px`) : "13px"};
	height: ${({ size }) =>
		size ? (String(size).endsWith("px") ? size : `${size}px`) : "13px"};
	mask-repeat: no-repeat;
	mask-size: 100%;
	margin: 0px 10px;
`;

const NavBarContainer = () => {
	const { t } = useTranslation("header");
	const router = useRouter();
	const { locale } = router;
	const { RouterLink } = useLinkTranslation();
	const account_type = useSelector(
		(state: RootState) => state?.userInfo?.account_type
	);
	const [agencyProfile, setAgencyProfile] = useState(null);
	const [logoImage, setLogoImage] = useState("");

	const navBarList =
		account_type === "seller" ? sellerNavBarList() : agencyNavBarList();

	const _getAgencyProfile = async () => {
		try {
			let _agencyProfile;
			const agencyString = localStorage.getItem("agency");
			if (agencyString) {
				_agencyProfile = JSON.parse(agencyString);
			}
			setAgencyProfile({ ..._agencyProfile });
			setLogoImage(_agencyProfile?.logo_image);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		_getAgencyProfile();
	}, []);

	return (
		<>
			{account_type === "seller" ? (
				<div className="d-none d-lg-block nav-bar mr-lg-2">
					<ul className="p-0 mb-0">
						{navBarList.map((list, index) => {
							{
								return (
									<RouterLink href={list.href} key={index} locale={locale}>
										<li
											className={
												router.pathname.includes(list.href) ? "active" : ""
											}
										>
											<MaskedIcon
												selected={router.pathname.includes(list.href)}
												icon={list.img}
											/>

											{t(`nav-li.${list.id}`)}
										</li>
									</RouterLink>
								);
							}
						})}
					</ul>
				</div>
			) : (
				<div className="d-none d-lg-block nav-bar mr-lg-2">
					<div
						className="upper-section d-flex justify-content-center"
						style={{ gap: "12px" }}
					>
						<div
							className="img-container"
							style={{
								height: "30px",
								width: "30px",
								borderRadius: "50%",
								overflow: "hidden",
							}}
						>
							<img
								src={logoImage}
								alt=""
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
							/>
						</div>
						<div
							className="content d-flex flex-column"
							style={{ width: "122px" }}
						>
							<span
								style={{
									fontSize: "14px",
									fontWeight: "400px",
									color: "#1D2E5B",
								}}
							>
								{agencyProfile?.company_name}
							</span>
							<span
								style={{
									fontSize: "12px",
									fontWeight: "400px",
									color: "#8F99B4",
									textTransform: "capitalize",
								}}
							>
								{`${agencyProfile?.city}`}
							</span>
						</div>
						<span>
							<img src={ArrowIcon} />
						</span>
					</div>
					<hr />
					<ul className="p-0 mb-0">
						{navBarList.map((list, index) => {
							{
								return (
									<RouterLink href={list.href} key={index} locale={locale}>
										<li
											className={
												router.pathname.includes(list.href) ? "active" : ""
											}
										>
											<MaskedIcon
												selected={router.pathname.includes(list.href)}
												icon={list.img}
											/>

											{t(`nav-li.${list.id}`)}
										</li>
									</RouterLink>
								);
							}
						})}
					</ul>
				</div>
			)}
		</>
	);
};

export default NavBarContainer;
