import { NavBarLists } from "../types/nav-bar-lists";
import dashboardIcon from "../assets/images/nav-bar/dashboard.svg";
import sellerLeadsIcon from "../assets/images/seller-leads/seller-leads-icon.png";
import settingsIcon from "../assets/images/seller-leads/settings-icon.png";
import propertiesIcon from "../assets/images/nav-bar/properties.svg";
import savedPropertyIcon from "../assets/images/saved-properties-icon.png";
import saveSearchesNew from "../assets/images/saved-searches-new.png";
import accountSettingsNew from "../assets/images/account-settings-new.png";
// import settingsIcon from "../assets/images/nav-bar/settings.svg";

const dashboard: NavBarLists = {
	id: "dashboard",
	href: "/dashboard",
	img: dashboardIcon,
};

const myProperties: NavBarLists = {
	id: "myProperties",
	href: "/properties",
	img: propertiesIcon,
};

const myEstimations: NavBarLists = {
	id: "myEstimates",
	href: "/properties",
	img: propertiesIcon,
};

const settings: NavBarLists = {
	id: "settings",
	href: "/settings",
	img: accountSettingsNew,
};

const agencySettings: NavBarLists = {
	id: "agency-settings",
	href: "/agency-settings",
	img: settingsIcon,
};

const sellerLeads: NavBarLists = {
	id: "sellerLeads",
	href: "/appointments",
	img: sellerLeadsIcon,
};

const savedProperties: NavBarLists = {
	id: "savedProperties",
	href: "/saved-property",
	img: savedPropertyIcon,
};

const savedSearchPreferences:  NavBarLists = {
	id: "savedSearches",
	href: "/saved-searches",
	img: saveSearchesNew,
};

const dataBox: NavBarLists = {
	id: "statistics",
	href: "/statistics",
	img: sellerLeadsIcon,
};

const sellerNavBarList = () => {
	return [myProperties, settings];
};

const sellerNavBarListNew = () => {
	return [myEstimations, savedSearchPreferences, savedProperties, settings];
};

const agencyNavBarList = () => [sellerLeads, dataBox, settings, agencySettings];

export { agencyNavBarList, sellerNavBarList, sellerNavBarListNew };
