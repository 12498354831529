import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

import { Image } from "react-bootstrap";
import Drawer from "antd/lib/drawer";
import { isMobileOnly } from "react-device-detect";

// import Logo from "../../assets/images/belgium_immo_logo.svg";
// import Logo from "../../assets/images/proppy-header-2.svg";
import Logo from "../../assets/images/Logo-proppy.png";
import NoPhoto from "../../assets/images/no-photo.png";
import AddIcon from "../../assets/images/icon-plus.svg";
import LogoutIcon from "../../assets/images/nav-bar/logout.svg";
import EstimateHomeIcon from "../../assets/images/nav-bar/estimate-home.svg";
import CompareAgencyIcon from "../../assets/images/nav-bar/compare-agency.svg";
import CheckedIcon from "../../assets/images/valid-blue.svg";
import NunitoSans from "../../assets/fonts/NunitoSans-Regular.ttf";
import NunitoSansBold from "../../assets/fonts/NunitoSans-Bold.ttf";
import CurrentStepIcon from "../../assets/images/header-step-current.svg";
import SuccessStepIcon from "../../assets/images/header-step-success.svg";
import BlogsIcon from "../../assets/images/nav-bar/blogs.svg";
// import compareagencyImage from "../../assets/images/compareagency.png";
// import blogImage from "../../assets/images/blogs.png";

import { RootState } from "../../types/state";
import { agencyNavBarList, sellerNavBarListNew } from "../../config/navBarList";
// import { config } from "../../config/siteConfigs";
import {
	// clearStepsStateAction,
	setUserProfile,
	userLogoutAction,
} from "../../actions";
import styled from "styled-components";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import { MdArrowDropDown } from "react-icons/md";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import { MaskedIcon } from "../NavBar";
import { Divider } from "antd";
import { signOut } from "next-auth/react";

const LatLngs = styled.div`
	display: flex;
	/* margin-top: 50%; */
	align-items: center;
	justify-content: center;

	.active {
		background: var(--colorBlue);
		color: var(--colorWhite);
	}

	span {
		text-transform: capitalize;
		margin: 0 6px;
		font-family: var(--fontNunitoBold);
		font-size: 14px;
		line-height: 19px;
		background: var(--bg-blue);
		border-radius: 6px;
		padding: 6px 10px;
	}
`;
const langList = [
	{
		id: "en",
		tag: "en",
		label: "english",
	},
	{
		id: "fr",
		tag: "fr",
		label: "french",
	},
	{
		id: "nl",
		tag: "nl",
		label: "dutch",
	},
];

const HeaderContainer = ({
	title,
	step,
	metaDescription,
	canonicalHref,
	seoImage,
	isVisible = true,
	structuredData = {},
	pathParams = {},
	isHeaderShadowVisible = true,
	forceShadow = false,
	customLanguageSelectorPaths = null,
	hideItemsForAgent = false,
	redirected = false,
}: {
	title: string;
	mainPage?: boolean;
	step?: number;
	metaDescription?: string;
	canonicalHref?: string;
	isVisible?: boolean;
	structuredData?: any;
	pathParams?: any;
	seoImage?: string;
	isHeaderShadowVisible?: boolean;
	forceShadow?: boolean;
	hideItemsForAgent?: boolean;
	customLanguageSelectorPaths?: any;
	redirected?: boolean;
}) => {
	const router = useRouter();

	const dispatch = useDispatch();
	const { RouterLink, routerPush } = useLinkTranslation();

	const { locale, pathname, asPath } = router;
	const { t } = useTranslation("header");
	const account_type = useSelector(
		(state: RootState) => state?.userInfo?.account_type
	);
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	const navBarList =
		account_type === "seller" ? sellerNavBarListNew() : agencyNavBarList();

	const { auth, firstname, lastname, avatar } = useSelector(
		(state: RootState) => state.userInfo
	);

	const isLoggedIn = auth;

	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [openLangList, setOpenLangList] = useState<boolean>(false);

	const navLinks = [
		{
			name: t("a.buy"),
			href: "/search/properties/for-sale",
			icon: EstimateHomeIcon,
		},
		{
			name: t("a.rent"),
			href: "/search/properties/for-rent",
			icon: EstimateHomeIcon,
		},
		{
			name: t("a.estimate"),
			href: "/new-estimate",
			icon: EstimateHomeIcon,
		},
		{
			name: t("real-estate-agencies"),
			href:"/compare-agency",
			icon: CompareAgencyIcon,
		},
		{
			name: t("nav-li.blogs"),
			href: "/blogs",
			icon: BlogsIcon,
		},
	];

	const goToMainPage = () => {
		routerPush(`/new-estimate`, undefined, { locale });
	};

	const Logout = async (account_type) => {
		await signOut({ redirect: false });
		dispatch(userLogoutAction(account_type));
	};

	const openSwitcherBlock = () => {
		setOpenLangList(!openLangList);
	};

	const selectLang = (lang: any) => {
		if (customLanguageSelectorPaths) {
			routerPush(customLanguageSelectorPaths[lang], undefined, {
				locale: lang,
			});
		} else {
			let _pathname = pathname;
			if (_pathname.includes("[")) {
				_pathname = _pathname.replaceAll("[city]", pathParams?.city);
				_pathname = _pathname.replaceAll("[agencyId]", pathParams?.agencyId);
				_pathname = _pathname.replaceAll("[reviewId]", pathParams?.reviewId);
				_pathname = _pathname.replaceAll(
					"[propertyId]",
					pathParams?.propertyId
				);
				_pathname = _pathname.replaceAll("[slug]", pathParams?.slug);
				_pathname = _pathname.replaceAll(
					"[listing_type]",
					pathParams?.listing_type
				);
			}

			const split = asPath.split("?");
			if (split.length > 1) {
				_pathname = _pathname + "?" + split[1];
			}
			routerPush(_pathname, undefined, {
				locale: lang,
			});
		}
	};

	// const goToLoginPage = () => {
	// 	dispatch(clearStepsStateAction());
	// 	routerPush("/login");
	// };

	const onScroll = () => {
		setScrollHeight(window.pageYOffset);
	};

	useEffect(() => {
		// i18n.changeLanguage(locale);
	}, [locale]);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	useEffect(() => {
		if (window) {
			const userProfile = window.localStorage.getItem("user_profile");
			if (userProfile) {
				dispatch(
					setUserProfile({
						...JSON.parse(userProfile),
					})
				);
			}
		}
	}, []);

	return (
		<>
			<Head>
				<title>{title}</title>
				<link rel="icon" href={"/favicon.ico"} />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href={"/apple-touch-icon.png"}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="26x26"
					href={"/favicon-26x26.png"}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href={"/favicon-16x16.png"}
				/>
				<link rel="manifest" href={"/site.webmanifest"} />
				<link rel="mask-icon" href={"/safari-pinned-tab.svg"} color="#3871ef" />

				<link rel="prefetch" type="font/ttf" as="font" href={NunitoSans} />
				<link rel="prefetch" type="font/ttf" as="font" href={NunitoSansBold} />
				{/* {mainPage && !isMobile && (
					<link rel="preload" as="image" href={FirstSlide} />
				)} */}
				{/* <meta name="robots" content="noindex, nofollow" /> */}
				<meta name="apple-mobile-web-app-title" content="BelgiumImmo" />
				<meta name="application-name" content="BelgiumImmo" />
				<meta name="msapplication-TileColor" content="#3871ef" />
				<meta
					name="facebook-domain-verification"
					content="30qmvq4ldi8cytef4h7ao0hwkcvp4s"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta name="theme-color" content="#3871ef" />
				<meta name="description" content={metaDescription} />
				{canonicalHref && <link rel="canonical" href={canonicalHref} />}
				{canonicalHref && <meta property="og:url" content={canonicalHref} />}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={metaDescription} />
				{seoImage && <meta property="og:image" content={seoImage} />}
				{seoImage && <meta property="og:image:width" content="1200" />}
				{seoImage && <meta property="og:image:height" content="630" />}
				{structuredData && (
					<script
						key="structured-data"
						type="application/ld+json"
						dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
					/>
				)}
			</Head>
			<div
				className={`Header ${
					isVisible ? "d-flex" : "d-none"
				} justify-content-between align-items-center fixed-top ${
					forceShadow
						? "custom-header-shadow"
						: scrollHeight > 30 && isHeaderShadowVisible
						? "shadow"
						: ""
				}`}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Drawer
						style={{ zIndex: 3000 }}
						open={openDrawer}
						onClose={() => setOpenDrawer(false)}
						width={"100%"}
						closable={false}
						title={
							<div>
								<CloseOutlined
									onClick={() => setOpenDrawer(false)}
									style={{ marginRight: 30 }}
								/>
								{account_type !== "agent" && !hideItemsForAgent ? (
									<RouterLink href="/">
										<a>
											<Image
												className={`logo`}
												width={104}
												height={26}
												src={Logo}
												alt="Logo"
											/>
										</a>
									</RouterLink>
								) : (
									<Image
										className={`logo`}
										width={104}
										height={26}
										src={Logo}
										alt="Logo"
										style={{
											cursor: "default",
										}}
									/>
								)}
							</div>
						}
					>
						<div className="d-flex flex-column h-100 justify-content-between">
							<div style={{ marginTop: 20, overflowY: "auto" }}>
								{true &&
									navLinks.map((el, index) => {
										return (
											<RouterLink
												shallow={false}
												href={el.href}
												key={index}
												locale={locale}
											>
												<a>
													<div className="d-flex flex-row align-items-center mb-3">
														<MaskedIcon
															selected={router.pathname === el.href}
															icon={el.icon}
														/>

														<div
															style={{
																color:
																	router.pathname === el.href
																		? "var(--colorBlue)"
																		: "#1D2E5B",
																fontSize: 16,
															}}
														>
															{el.name}
														</div>
													</div>
												</a>
											</RouterLink>
										);
									})}
								{account_type !== "agent" && !hideItemsForAgent && auth && (
									<Divider className="w-100" />
								)}
								{auth &&
									navBarList.map((el, index) => {
										return (
											<RouterLink href={el.href} key={index} locale={locale}>
												<a>
													<div className="d-flex flex-row align-items-center mb-3">
														<MaskedIcon
															selected={router.pathname === el.href}
															icon={el.img}
														/>

														<div
															style={{
																color:
																	router.pathname === el.href
																		? "var(--colorBlue)"
																		: "#1D2E5B",
																fontSize: 16,
															}}
														>
															{t(`nav-li.${el.id}`)}
														</div>
													</div>
												</a>
											</RouterLink>
										);
									})}
								{auth && (
									<div
										style={{ cursor: "pointer" }}
										onClick={Logout}
										className="d-flex flex-row align-items-center mb-3"
									>
										<MaskedIcon selected={false} icon={LogoutIcon} />

										<div
											style={{
												color: "#1D2E5B",
												fontSize: 16,
											}}
										>
											{t(`nav-li.logout`)}
										</div>
									</div>
								)}
							</div>
							<div className="d-flex flex-column justify-content-end pb-4">
								{auth && (
									<div
										style={{ cursor: "pointer", marginBottom: 40 }}
										className="d-flex flex-row align-items-center justify-content-center"
									>
										<RouterLink href="/settings">
											<div className="d-flex flex-row align-items-center">
												<Image
													style={{ width: 30, height: 30 }}
													alt="avatar"
													className="mr-2"
													src={avatar ? avatar : NoPhoto}
													roundedCircle
												/>
												<span
													style={{
														color: "var(--mainColor)",
														fontSize: 18,
													}}
												>
													{firstname || "" + " " + lastname || ""}
												</span>
											</div>
										</RouterLink>
									</div>
								)}

								<LatLngs>
									{langList.map((lang, index) => (
										<span
											className={lang.id === locale ? "active" : ""}
											key={index}
											onClick={() => selectLang(lang.id)}
										>
											{lang.label}
										</span>
									))}
								</LatLngs>
							</div>
						</div>
					</Drawer>

					<MenuOutlined
						style={{ marginRight: 15 }}
						onClick={() => setOpenDrawer(true)}
						className="d-flex d-lg-none"
					/>

					{account_type !== "agent" && !hideItemsForAgent ? (
						<RouterLink href="/">
							<a>
								<Image
									className={`logo`}
									width={104}
									height={26}
									src={Logo}
									alt="Logo"
								/>
							</a>
						</RouterLink>
					) : (
						<Image
							className={`logo`}
							width={104}
							height={26}
							src={Logo}
							alt="Logo"
							style={{
								cursor: "default",
							}}
						/>
					)}

					{account_type !== "agent" &&
						!hideItemsForAgent &&
						!step &&
						step != 0 && (
							<div className="custom-nav-links-container">
								{navLinks.map(({ href, name }) => {
									return (
										<RouterLink
											shallow={false}
											replace={true}
											key={name}
											href={href}
											locale={locale}
										>
											<a className="n-link-custom">{name}</a>
										</RouterLink>
									);
								})}
								{/* <RouterLink href="/estimate-home">
								<a className="n-link-custom">{t("a.estimate-your-home")}</a>
							</RouterLink>
							<RouterLink href={`/agencies`}>
								<a className="n-link-custom">
									{t("a.custom-link-compare-agencies")}
								</a>
							</RouterLink> */}
								{/* <div className="dropdown-custom">
								<div style={{ marginBottom: 5 }} className="n-link-custom">
									{t("a.estimate-your-home")}
									<MdArrowDropDown className="ml-1" style={{ fontSize: 16 }} />
								</div>
								<div className="dropdown-content">
									<RouterLink href="/estimate-home">
										<a className="n-link-custom">{t("a.estimate-your-home")}</a>
									</RouterLink>
									<RouterLink href="/cost-calculator">
										<a className="n-link-custom">{t("Cost Calculator")}</a>
									</RouterLink>
								</div>
							</div> */}
								{/* <div className="dropdown-custom">
								<div style={{ marginBottom: 5 }} className="n-link-custom">
									{t("a.custom-link-compare-agencies")}
									<MdArrowDropDown className="ml-1" style={{ fontSize: 16 }} />
								</div>
								<div className="dropdown-content">
									<RouterLink href={`/find-agent`}>
										<a className="n-link-custom">{t("a.find-your-agent")}</a>
									</RouterLink>
								</div>
							</div> */}
								{/* <RouterLink href="/blogs">
								<a className="n-link-custom">Blogs</a>
							</RouterLink> */}
							</div>
						)}
				</div>
				{step != null && step < 3 && (
					<div className={scrollHeight > 30 ? "d-none" : "step-info"}>
						<div
							className={`header-step-one ${step === 0 ? "active-step" : ""}`}
						>
							<div className={`image-block ${step !== 0 ? "success" : ""}`}>
								<img
									src={step !== 0 ? SuccessStepIcon : CurrentStepIcon}
									alt="steps-icon"
								/>
							</div>
							{t("span.step")} 1
						</div>
						<div
							className={`header-step-two ${step === 1 ? "active-step" : ""}`}
						>
							<div className={`image-block ${step > 1 ? "success" : ""}`}>
								{step >= 1 && (
									<img
										src={step > 1 ? SuccessStepIcon : CurrentStepIcon}
										alt="steps-icon"
									/>
								)}
							</div>
							{t("span.step")} 2
						</div>
						<div
							className={`header-step-three ${step > 1 ? "active-step" : ""}`}
						>
							<div className="image-block">
								{step >= 2 && <img src={CurrentStepIcon} alt="steps-icon" />}
							</div>
							{t("span.step")} 3
						</div>
					</div>
				)}
				<div className="d-flex align-items-center">
					{auth ? (
						<>
							<div className="d-none d-md-block resp-container">
								<Dropdown
									arrow
									overlay={
										<div
											style={{
												backgroundColor: "white",
												boxShadow: "0px 4px 10px rgba(103, 139, 216, 0.3)",
												borderRadius: "10px",
												padding: 20,
											}}
										>
											{navBarList.map((el, index) => {
												return (
													<RouterLink
														href={el.href}
														key={index}
														locale={locale}
													>
														<a className="nav-li-container">
															<div className="d-flex flex-row align-items-center mb-3">
																<MaskedIcon
																	selected={router.pathname === el.href}
																	icon={el.img}
																/>

																<div
																	style={{
																		color:
																			router.pathname === el.href
																				? "var(--colorBlue)"
																				: "#1D2E5B",
																		fontSize: 16,
																	}}
																>
																	{t(`nav-li.${el.id}`)}
																</div>
															</div>
														</a>
													</RouterLink>
												);
											})}
											<div
												style={{ cursor: "pointer" }}
												onClick={() => Logout(account_type)}
												className="d-flex flex-row align-items-center"
											>
												<MaskedIcon selected={false} icon={LogoutIcon} />

												<div
													style={{
														color: "#1D2E5B",
														fontSize: 16,
													}}
												>
													{t(`nav-li.logout`)}
												</div>
											</div>
										</div>
									}
									trigger={["click"]}
								>
									<div
										style={{ cursor: "pointer" }}
										className="d-flex flex-row align-items-center"
									>
										<Image
											style={{ width: 30, height: 30 }}
											alt="avatar"
											className="mr-2"
											src={avatar ? avatar : NoPhoto}
											roundedCircle
										/>
										<span
											style={{ color: "var(--mainColor)" }}
											className="d-none d-md-block"
										>
											{firstname || "" + " " + lastname || ""}
										</span>
									</div>
								</Dropdown>
							</div>
							<RouterLink href="/settings">
								<Image
									style={{ width: 30, height: 30 }}
									alt="avatar"
									className="mr-2 d-block d-md-none"
									src={avatar ? avatar : NoPhoto}
									roundedCircle
								/>
							</RouterLink>
						</>
					) : (
						<RouterLink href={`/login${redirected ? "?redirected=true" : ""}`}>
							<a rel="nofollow">
								<span className="sign-in-btn resp-container">
									{t("button.login")}
									{/* <img src={LoginArrow} alt="LoginArrow" /> */}
								</span>
							</a>
						</RouterLink>
					)}
					<div
						className={`switcher-lang position-relative d-none d-md-block ${
							openLangList ? "active-locale" : ""
						}`}
					>
						<span onClick={openSwitcherBlock}>{locale}</span>
						{openLangList && (
							<div className={`lang-list ${!auth ? "p-right" : ""}`}>
								{langList.map((lang, index) => (
									<span
										className={lang.id === locale ? "active" : ""}
										key={index}
										onClick={() => selectLang(lang.id)}
									>
										{lang.tag}
										{lang.id === locale && (
											<img src={CheckedIcon} alt="CheckedIcon" />
										)}
									</span>
								))}
							</div>
						)}
					</div>
					{/* {isLoggedIn && account_type === "seller" && (
						<div
							style={{
								borderRadius: 6,
								background: "var(--colorBlue)",
								height: 35,
								color: "white",
								cursor: "pointer",
							}}
							className="d-none d-md-flex flex-row align-items-center px-3 ml-2 ml-md-4"
							onClick={goToMainPage}
						>
							<img
								style={{ width: 14, height: 14 }}
								src={AddIcon}
								alt="AddIcon"
							/>
							<span className="d-none d-md-flex ml-2">
								{t("button.add-property")}
							</span>
						</div>
					)} */}
				</div>
			</div>
		</>
	);
};

export default HeaderContainer;

// <div className="d-flex align-items-center">
// 	{false ? (
// 		<div className="right-block d-flex align-items-center">
// 			{!step && (
// 				<>
// 					{!openMenu && (
// 						<Image
// 							className="user-avatar"
// 							src={avatar ? avatar : NoPhoto}
// 							alt="avatar"
// 							roundedCircle
// 						/>
// 					)}
// 					<NavDropdown
// 						title={isMobileOnly ? "" : firstname + " " + lastname}
// 						id="header-dropdown"
// 						onClick={isActive}
// 					>
// 						{/* {account_type === "seller" && (
// 											<NavDropdown.Item
// 												href={"/" + locale + "/pro-workspace"}
// 												className="pro-workspace"
// 											>
// 												<img src={ProIcon} alt="ProIcon" />
// 												{t("li.pro-workspace")}
// 											</NavDropdown.Item>
// 										)} */}
// 						{/* {isMobileOnly && (
// 											<div>
// 												<Button
// 													onClick={goToMainPage}
// 													className="add-property-mobile"
// 												>
// 													<img src={AddIcon} alt="AddIcon" />
// 													<span>{t("button.add-property")}</span>
// 												</Button>
// 											</div>
// 										)} */}
// 						{navBarList.map((list, index) => {
// 							if (
// 								width >= 500 &&
// 								(list.id === "priceMap" ||
// 									list.id === "compareAgencies" ||
// 									list.id === "blogs")
// 							) {
// 							} else {
// 								return (
// 									<NavDropdown.Item href={"/" + locale + list.href} key={index}>
// 										<img src={list.img} />
// 										{t(`nav-li.${list.id}`)}
// 									</NavDropdown.Item>
// 								);
// 							}
// 						})}
// 						<NavDropdown.Item onClick={Logout}>
// 							<img className="logout-image" src={LogoutIcon} alt="logout" />
// 							Logout
// 						</NavDropdown.Item>
// 						{/* {isMobileOnly && (
// 											<div className="mobile-block">
// 												<Image
// 													alt="avatar"
// 													className="user-avatar"
// 													src={NoPhoto}
// 													roundedCircle
// 												/>
// 												<span className="user-name">
// 													{firstname + " " + lastname}
// 												</span>
// 												<div className="mobile-lang-list">
// 													{langList.map((lang, index) => (
// 														<span
// 															className={lang.id === locale ? "active" : ""}
// 															key={index}
// 															onClick={() => selectLang(lang.id)}
// 														>
// 															{lang.label}
// 														</span>
// 													))}
// 												</div>
// 											</div>
// 										)} */}
// 					</NavDropdown>
// 					{!isMobileOnly && (
// 						<div
// 							className={`switcher-lang position-relative ${
// 								openLangList ? "active-locale" : ""
// 							}`}
// 						>
// 							<span onClick={openSwitcherBlock}>{locale}</span>
// 							{openLangList && (
// 								<div className="lang-list">
// 									{langList.map((lang, index) => (
// 										<span
// 											className={lang.id === locale ? "active" : ""}
// 											key={index}
// 											onClick={() => selectLang(lang.id)}
// 										>
// 											{lang.tag}
// 											{lang.id === locale && (
// 												<img src={CheckedIcon} alt="CheckedIcon" />
// 											)}
// 										</span>
// 									))}
// 								</div>
// 							)}
// 						</div>
// 					)}
// 					{!openMenu && (
// 						<>
// 							{isLoggedIn && account_type === "seller" && (
// 								<Button className="add-property" onClick={goToMainPage}>
// 									<img src={AddIcon} alt="AddIcon" />
// 									<span>{t("button.add-property")}</span>
// 								</Button>
// 							)}
// 						</>
// 					)}
// 				</>
// 			)}
// 		</div>
// 	) : (
// 		<RouterLink href="/login">
// 			<span className="sign-in-btn">
// 				{t("button.login")} <img src={LoginArrow} alt="LoginArrow" />
// 			</span>
// 		</RouterLink>
// 	)}
// 	{!auth && (
// 		<div
// 			className={`switcher-lang position-relative ${
// 				openLangList ? "active-locale" : ""
// 			}`}
// 		>
// 			<span onClick={openSwitcherBlock}>{locale}</span>
// 			{openLangList && (
// 				<div className={`lang-list ${!auth ? "p-right" : ""}`}>
// 					{langList.map((lang, index) => (
// 						<span
// 							className={lang.id === locale ? "active" : ""}
// 							key={index}
// 							onClick={() => selectLang(lang.id)}
// 						>
// 							{lang.tag}
// 							{lang.id === locale && (
// 								<img src={CheckedIcon} alt="CheckedIcon" />
// 							)}
// 						</span>
// 					))}
// 				</div>
// 			)}
// 		</div>
// 	)}
// </div>;
